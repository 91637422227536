import { BookingCreateData } from "../api";
import { AppSettingsDto, BookingBaseDto, HotelId, SettingsDto } from "../api/types";
import { CountryType } from "../data/countries";
import { env } from "../env";
import { dynamicContentParentPath, PATHS } from "../paths";
import { url } from "../utils";
import { AplicationParams } from "./app";
import { Room } from "./room";

export interface BookingSettings {
  occupancy: number;
  dateFrom: Date;
  dateTo: Date;
  maxDate?: Date;
}

export interface CommonSettings {
  hotelId: HotelId;
  appUrl: string;
  marketingPages: {
    marketingNewsletter: string;
    reservationConditions: string;
  };
  title: string;
  // return url for payment gateway
  gatewayReturnUrl: string;
  primaryColor?: string;
}

export interface AppSettings {
  common: CommonSettings;
  booking: BookingSettings;
}

export interface PurchaseFormData {
  type: "individual" | "company";
  companyName?: string;
  ico?: string;
  dic?: string;
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  zip: string;
  email: string;
  country: CountryType;
  phone: string;
  paymentMethod: string;
  reservationConditions: {
    agreeToTermsAndPrivacy: boolean;
    agreeToHotelMarketing: boolean;
  };
}

export const parseAppSettings = ({ objectInfo, appSettings }: AppSettingsDto): AppSettings => {
  const dateFrom = new Date(appSettings.defaultStartDate);
  const dateTo = new Date(dateFrom);
  dateTo.setDate(dateTo.getDate() + 1);

  return {
    common: {
      hotelId: objectInfo.id,
      appUrl: appSettings.appUrl,
      marketingPages: {
        marketingNewsletter: dynamicContentParentPath(appSettings.marketingPages.marketingNewsletter.url),
        reservationConditions: dynamicContentParentPath(appSettings.marketingPages.reservationConditions.url),
      },
      title: objectInfo.title,
      primaryColor: appSettings.primaryColor,
      gatewayReturnUrl: url.stringify(env.PROD_URL + PATHS.PAYMENT_CONFIRMATION, { appid: objectInfo.id } as Record<
        AplicationParams,
        string | undefined
      >),
    },
    booking: {
      occupancy: 1,
      dateFrom: dateFrom,
      dateTo: dateTo,
      maxDate: appSettings.maxDate ? new Date(appSettings.maxDate) : undefined,
    },
  };
};

export const toSettingsDto = ({ common, booking }: AppSettings): SettingsDto => ({
  objectId: common.hotelId,
  returnUrl: common.gatewayReturnUrl,
  startDate: booking.dateFrom.toISOString(),
  endDate: booking.dateTo.toISOString(),
});

export const toBookingsDto = (bookings: Room[]): BookingBaseDto[] =>
  bookings.map((b) => ({ unitId: b.id, price: b.totalPrice }));

export const toBookingCreateData = (
  settings: AppSettings,
  bookings: Room[],
  { firstName, lastName, email, city, country, companyName, zip, street, phone, paymentMethod }: PurchaseFormData,
): BookingCreateData => ({
  settings: toSettingsDto(settings),
  bookings: toBookingsDto(bookings),
  invoiceDetails: {
    name: firstName,
    surname: lastName,
    street,
    city,
    zip,
    country: country.label,
    companyName,
  },
  contactDetails: {
    name: firstName,
    surname: lastName,
    email,
    phone,
  },
  paymentMethod: paymentMethod as any,
});
